<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>SMS</h1>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <section class="content">            
            <div style="display: flex;margin-top: 25px; justify-content: space-between">
                <div style="display: flex; gap: 30px;">
                    <div class="form-group">
                        <label for="">Usuario</label>
                        <select style="width: 300px;" class="form-control" v-model="usuarioSeleccionado" id="">
                            <option value="">---</option>
                            <option v-for="usuario in usuarios" :key="usuario.id" :value="usuario.nombre">{{ usuario.nombre }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="">Teléfono</label>
                        <input type="text" class="form-control" id="" v-model="telefono">
                    </div>
                </div>
                <div>
                    <div class="callout">
                        <label for="" style="margin: 0;">Saldo actual</label>
                        <div style="display: flex; gap: 5px;">
                            <span>{{ creditos }}</span>
                            <span>Créditos</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <tabla-datos
                    :columnas="columnas"
                    :datos="smsListado"
                    :total="total"
                    :filtros="false"
                    @quieroDatos="obtenerSMS($event); mievento=$event"
                    :botones="true"
                    :botoncrear="true"
                   >                
                </tabla-datos>
            </div>
        </section>
    </div>
</template>
<script>
import TablaDatos from '../../../TablaDatos.vue';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    //crear plantilla texto pide nombre y tipo
    components: {
        'tabla-datos': TablaDatos,
    },
    data() {
        return {
            mievento:[],
            total:25,
            creditos:'',
            usuarioSeleccionado:'',
            usuarios:[],
            telefono:'',
            smsListado:[],
            columnas: [
                {
                    header: 'Fecha de envío',
                    field: 'fecha_sms',
                },
                {
                    header: 'Número',
                    field: 'numero_sms',                
                },
                {
                    header: 'Usuario',
                    field: 'usuario_sms',
                },
                {
                    header: 'Texto',
                    field: 'texto_sms',
                },
                {
                    header: 'Diferido',
                    field: 'diferido_sms',
                },
            ]
        }
    },
    methods: {
        async obtenerCreditos() {
            const api = new PwgsApi();  
            try {
                const response = await api.get('sms-pwgs/creditos');
                this.creditos = response.creditos;
            } catch (error) {
                console.error('Error al obtener creditos', error);
            }
        },
        async obtenerSMS(parametros){
            const api = new PwgsApi();
            console.log('enenen');
            try{
                if(this.telefono != ''){
                    parametros.numero = parseInt(this.telefono);
                }
                if(this.usuarioSeleccionado!= ''){
                    parametros.usuario = this.usuarioSeleccionado;
                }
                const response = await api.post('sms-pwgs', parametros);
                this.total = response.n_total_registros;
                this.smsListado = response.datos;
                for(let sms of this.smsListado){
                    sms.fecha_sms = this.timestampToDateTime(sms.fecha_sms);
                }
                console.log('smslistado',this.smsListado);
            }catch (error) {
                console.error('Error al obtener SMS', error);
            }
        },
        timestampToDateTime(timestamp) {
            // Convertir el timestamp de segundos a milisegundos
            const date = new Date(timestamp * 1000);

            // Obtener el día, mes, año, horas, minutos y segundos
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            // Formatear la fecha y la hora como DD/MM/YYYY HH:mm:ss
            return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
        },
        async obtenerUsuarios() {
            const api = new PwgsApi();  
            try {
                const response = await api.get('usuarios/tramitadores,simple');
                this.usuarios = response.datos;
            } catch (error) {
                console.error('Error al obtener usuarios', error);
            }
        },
    },
    watch:{
          usuarioSeleccionado(value){
            if(value == ''){
                delete this.mievento['usuario']
            }
            this.obtenerSMS(this.mievento);
          },
          telefono(value){
            if(value == ''){
                delete this.mievento['numero']
            }
            this.obtenerSMS(this.mievento);
          }
    },
    mounted() { 
        this.obtenerSMS();
        this.obtenerCreditos();
        this.obtenerUsuarios();
    },
}

</script>
